import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useGlobal } from '../store';
import GridItem from './GridItem';
import ListItem from './ListItem';
import SearchBar from './SearchBar';

export default function ProductList(props) {
  const [globalState, globalActions] = useGlobal();
  const [searchInput, setSearchInput] = useState("");
  const [displayOption, setDisplayOption] = useState("grid");

  function handleSearch(e) {
    if (e != "") {
      setSearchInput(e.target.value);
    } else {
      setSearchInput("");
    }
  }

  useEffect(() => {
    globalActions.product.getInitialValue();
  }, []);

  return (
    <div>
      <SearchBar
        displayOption={displayOption}
        setDisplayOption={setDisplayOption}
        searchInput={searchInput}
        handleSearch={handleSearch}
      />

      <Row className={`itemListContainer ${displayOption == "grid" ? "" : "pt-3"}`}>
        <Switch>
          <Route path="/factsheets">
            {globalState.products.map(product => (
              product.productType == "factsheet" ?
                product.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 || (product.code && product.code.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) ?
                  displayOption == "grid" ?
                    <GridItem key={product.id} product={product} />
                    :
                    <ListItem key={product.id} product={product} />
                  : null
                : null
            ))}
          </Route>
          <Route path="/brochures">
            {globalState.products.map(product => (
              product.productType == "brochure" ?
                product.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 || (product.code && product.code.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) ?
                  displayOption == "grid" ?
                    <GridItem key={product.id} product={product} />
                    :
                    <ListItem key={product.id} product={product} />
                  : null
                : null
            ))}
          </Route>
          <Route path="/promoproducts">
            {globalState.products.map(product => (
              product.productType == "promoproduct" ?
                product.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 || (product.code && product.code.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) ?
                  displayOption == "grid" ?
                    <GridItem key={product.id} product={product} />
                    :
                    <ListItem key={product.id} product={product} />
                  : null
                : null
            ))}
          </Route>
          <Redirect from="/" to="/factsheets" />
        </Switch>
      </Row>
    </div>
  );
}