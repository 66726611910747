import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import { useGlobal } from '../store';
import illustration from '../images/Webshop.png';
import cartEmpty from '../images/cartEmpty.svg';
import cartFull from '../images/cartFull.svg';

export default function OrderForm(props) {
    const [globalState, globalActions] = useGlobal();
    const [showMore, setShowMore] = useState(false);
    const canOrder = globalState.cart.length > 0 && globalState.customer !== '';

    let orderBtn = 
    <button
        className={`btnOrder ${canOrder && !globalState.orderLoading ? 'active' : ''}`}
        onClick={globalActions.orderForm.handleOrder}
        disabled={!canOrder && globalState.orderLoading ? 'disabled' : ''}>Place Order
    </button>;

    const refContainer = useRef();

    return (
        <div className="orderForm">
            <div className="icon">
                <h1 className="heading">Kaiser Partner <span className="bold">Web Shop</span></h1>
            </div>
            <Row><Col className="d-flex justify-content-center"><img className="illustration" src={illustration} /></Col></Row>

            <Row className="align-items-center form spac">
                <Col xs={12} sm={12} md={6} lg={8} className="p-0">
              
                    <Dropdown
                        className="peopleSelection"
                        placeholder='Type your Name'
                        fluid
                        search
                        selection
                        value={globalState.customer}
                        options={globalState.people}
                        onChange={globalActions.orderForm.handelSelectPerson}
                    />
                </Col>

                <Col xs="auto d-flex align-items-center p-0 ml-auto">
                    {globalState.orderLoading ?
                        <Spinner className="orderLoading" animation="border" variant="secondary" />
                        :
                        null
                    }
                    {canOrder ?
                        orderBtn
                        :
                        <OverlayTrigger
                            ref={refContainer}
                            placement="top"
                            delay={{ show: 100, hide: 400 }}
                            overlay={<Tooltip id={'tooltip-top'}>{`${!globalState.cart.length > 0 ? "Add a some items to your basket" : canOrder ? "" : "Select your name"}`}</Tooltip>}
                        >
                            {orderBtn}
                        </OverlayTrigger>
                    }
                </Col>
            </Row>

            <Row className="d-flex flex-row flex-row-reverse">
                <Col sm={12} md={6} lg={6}>
                    <Row className="overview">
                        <Col>
                            <button className={`${globalState.cart.length > 0 && showMore ? 'expand' : null} ${globalState.cart.length > 0 ? 'active' : null}`} onClick={() => setShowMore(!showMore)} disabled={!globalState.cart.length > 0 ? 'disabled' : null}>
                                <div className="text">{globalState.cart.length} <img src={!globalActions.orderForm.useCartEmpty() ? cartFull : cartEmpty} /></div>
                                <div className="cartList">
                                    {showMore ?
                                        globalState.cart.map(product => (
                                            <tr key={product.id}><td>{product.name}</td>&emsp;<td>{product.language}</td>&emsp;<td>({product.amount})</td>&emsp;</tr>
                                        ))
                                        : null}
                                </div>
                            </button>
                        </Col>
                    </Row>
                </Col>

                <Col sm="12 pt-4"  md="6 pt-0" lg="6 pt-0">
                    <p>
                        We are looking forward to your orders. Processing time is generally two working days, the delivery period depends on volume and stock of the order as well as the mailing route. If you have a very short-term request, please call us at 5400 when placing the order. 
                    </p>
                    <p>
                        Your 21iLAB team
                    </p>
                </Col>
            </Row>

        </div>
    );
}