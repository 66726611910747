import React, { useState, useEffect } from 'react';
import globalHook, { Store } from 'use-global-hook';
 
import * as actions from "../actions";

const initialState = {
  products: [],
  people: [],
  customer: '',
  ordered: false,
  orderLoading: false,
  cart: []
};
 
export const useGlobal = globalHook(React, initialState, actions);