import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { useGlobal } from '../store';

export default function ListItem(props) {
    const [globalState, globalActions] = useGlobal();

    return (
        <Col md="12" className="item">
            <Row className={`listItem ${props.product.selected ? 'active' : ''}`}>
                <Col xs="2" sm="2" md="1">
                    <a target="_blank" className='disable' href="">
                        <img className={`${props.product.status == 'Under review by business' ? 'inReview' : ''}`} src={props.product.thumbnail} />
                    </a>
                </Col>

                <Col xs="9" sm="10" md="4" className="d-flex flex-column">

                    {props.product.status == 'Under review by business' ? <span className="review-label">Under review by business</span> : ''}
                    {props.product.name}

                </Col>

                <Col xs="2" className="d-md-none"></Col>
                <Col xs="2" sm="2" md="1">{props.product.language}</Col>

                <Col xs="6" sm="6" md="4" className="align-items-end mr-auto"><span className={`factsheetCode ${props.product.code ? props.product.code.substring(0, 2) : ''}`}>{props.product.bu}</span></Col>
                <Col xs="2" sm="2" className={`d-flex align-items-end justify-content-end amount ${props.product.status == 'Under review by business' ? 'inReview' : ''}`} value={props.product.id}>
                    <button className={`btnAmount`} onClick={() => globalActions.product.handleAmount(props.product, "+")} disabled={props.product.status == 'Under review by business' ? 'disabled' : ''}>
                        <FaMinusCircle />
                    </button>
                    <span>{props.product.amount}</span>
                    <button className={`btnAmount`} value={props.product.id} onClick={() => globalActions.product.handleAmount(props.product, "+")} disabled={props.product.status == 'Under review by business' ? 'disabled' : ''}>
                        <FaPlusCircle />
                    </button>
                </Col>
            </Row>
        </Col>
    );
}