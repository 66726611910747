import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  NavLink
} from "react-router-dom";
import { Container, Col, Row } from 'react-bootstrap';

import './index.scss';
import { useGlobal } from './store';

import ProductListContainer from './components/ProductListContainer';
import OrderForm from './components/OrderForm';


export default function App() {
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    globalActions.orderForm.getInitialValue();
  }, []);

  return (
    <Router>
      <Container>
        {globalState.ordered === false ?
          <div>
            <OrderForm />

            <Row className="navigation">
              <NavLink to="/factsheets" activeClassName='active'><Col md="auto" className="p-0"><span className="category">Factsheets</span></Col></NavLink>
              <NavLink to="/brochures" activeClassName='active'><Col md="auto" className="p-0"><span className="category">Brochures</span></Col></NavLink>
              <NavLink to="/promoproducts" activeClassName='active'><Col md="auto" className="p-0"><span className="category">Promotional Products</span></Col></NavLink>
            </Row>

            <ProductListContainer />

          </div>
          :
          <Row className="thankYouScreen">
            <Col xs="12" className="text justify-content-center text-center">
              <h3>Thank you for your order.</h3>
              <button onClick={() => globalActions.orderForm.handleReturnToShop()}>Return to Shop</button>
            </Col>
          </Row>
        }
      </Container>
    </Router>
  );
}

