import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaTh, FaTimesCircle, FaList } from 'react-icons/fa';

export default function SearchBar(props) {
    return (
        <Row className="mb-2">
            <Col md="auto">
                <div className="searchInput">
                    <input placeholder="Search" type="text" onChange={(e) => props.handleSearch(e)} value={props.searchInput} />
                    {props.searchInput !== "" ?
                        <FaTimesCircle onClick={() => props.handleSearch("")} />
                        : null
                    }
                </div>
            </Col>
            <div className="displayOption pr-2">
                <button onClick={() => props.setDisplayOption("grid")} className={`${props.displayOption == "grid" ? 'active' : ''}`}><FaTh /></button>
                <button onClick={() => props.setDisplayOption("list")} className={`${props.displayOption == "list" ? 'active' : ''}`}><FaList /></button>
            </div>
        </Row>
    );
}