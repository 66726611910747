import axios from "axios";

import { airtableOps } from '../apiEndpoints';

export const getInitialValue = (store) => {
    if (sessionStorage.getItem('people')) {
        store.setState({ people: JSON.parse(sessionStorage.getItem('people')) });
    } else {
        let postData = {}
        postData['func'] = 'listEmployees';
        axios.post(airtableOps, postData)
            .then(function (response) {
                store.setState({ people: response.data })
                sessionStorage.setItem('people', JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.error(error);
            });
    }
}

export const handelSelectPerson = (store, e, data) => {
    store.setState({
        customer: data.value
    });
}

export const useCartEmpty = (store, e) => {
    var cartEmpty = true;
    var products = store.state.products
    for (var i in products) {
        if (products[i].selected) {
            cartEmpty = false;
            break;
        }
    }
    return cartEmpty
}

export const handleReturnToShop = (store) => {
    store.setState({
        ordered: false
    })
}

export const handleOrder = (store) => {
    var orderItems = [];
    var _store = store;
    var cart = store.state.cart;

    if (store.state.cart.length > 0 && store.state.customer !== "") {

        const customerObj = store.state.people.find(function (person) {
            return person.key === store.state.customer;
        });

        if (customerObj != null) {
            store.setState({
                orderLoading: true,
            })

            cart.forEach(product => {
                orderItems.push({
                    "fields": {
                        "Name": product.name,
                        "Language": product.language,
                        "Amount": product.amount,
                        "Code": product.code ? product.code : "",
                        "Printlink": product.printLink ? product.printLink : "",
                    }
                });
            });

            if (orderItems.length > 0) {
                let postData = {
                    ...{
                        func: 'createOrder',
                        orderItems: orderItems
                    },
                    ...customerObj
                }
                console.log(postData);
                axios.post(airtableOps, postData)
                    .then(function (response) {
                        console.log("ordered")
                        store.setState({
                            ordered: true,
                            orderLoading: false,
                            cart: [],
                            // products: []
                        });

                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        }
    }
}

