import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle, FaFilePdf } from 'react-icons/fa';

import { useGlobal } from '../store';

export default function GridItem(props) {
    const [globalState, globalActions] = useGlobal();
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        let elWidth = document.getElementsByClassName('image d-flex justify-content-center')[0].clientWidth;
        setImageWidth(elWidth);
    }, [] )

    function openDoc(url) {
        alert("For client meetings, we kindly ask you to use the printed version of the factsheets. In exceptional cases, the factsheet can be downloaded as a PDF if you wish to share it digitally. Please do not print out this version yourself to ensure high quality materials."); //TODO: add text for information
        window.open(url, '_blank');
    }

    return (
        <Col sx="12" sm="6" md="3" className="item">
            <Row className={`gridItem m-0 ${props.product.selected ? 'active' : ''}`}>
                <Col className="image d-flex justify-content-center" xs="auto" style={{ padding: '0px' }}>
                    {props.product.status == 'Under review by business' ? <span className="review-label">Under review by business</span> : ''}
                    <img width={"100%"} height={"100%"} className={`${props.product.productType} ${props.product.status == 'Under review by business' ? 'inReview' : ''}`} src={props.product.thumbnail} alt="" />
                    {props.product.productType != "promoproduct" && props.product.status != 'Under review by business' ? 
                    <div className="thumbnailOverlay">
                        <button onClick={() => openDoc(props.product.link)} target="_blank" className='btn' ><FaFilePdf /></button> 
                    </div>
                    : 
                    <a target="_blank" href={props.product.link} className="thumbnailOverlay thru"></a> 
                    }
                </Col>
                <Col className="d-flex mt-2">
                    <Row className="flex-column text">
                        <Col className="flex-column p-0 pt-1">
                            <span className="name">{props.product.name}</span><br />
                            <span className="additional">{props.product.language}</span><br />
                        </Col>
                        <Row className="ml-0 mr-0 mt-3 align-items-start flex-column h-min-content">
                            <Col className="d-flex align-items-end h-min-content p-0">
                                <span className={`factsheetCode ${props.product.code ? props.product.code.substring(0, 2) : ''}`}>{props.product.bu}</span>
                            </Col>

                            <Col className={`d-flex align-items-end justify-content-end amount h-min-content pt-2 ${props.product.productType} ${props.product.status == 'Under review by business' ? 'inReview' : ''}`}>
                                {props.product.productType == "promoproduct" ? <p style={{marginBottom: 5, paddingBottom: 0, paddingRight: 10, fontWeight: 'bold'}}>Coming soon</p> : null}
                                <button className="btnAmount" value={props.product.id} onClick={() => globalActions.product.handleAmount(props.product, "-")} disabled={props.product.status == 'Under review by business' ? 'disabled' : ''}>
                                    <FaMinusCircle />
                                </button>
                                <span>{props.product.amount}</span>
                                <button className="btnAmount" value={props.product.id} onClick={() => globalActions.product.handleAmount(props.product, "+")} disabled={props.product.status == 'Under review by business' ? 'disabled' : ''}>
                                    <FaPlusCircle />
                                </button>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
}
