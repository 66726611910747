import axios from "axios";

import { airtableOps } from '../apiEndpoints';

export const getInitialValue = (store) => {
    if (sessionStorage.getItem('products')) {
        store.setState({ products: JSON.parse(sessionStorage.getItem('products')) });
    } else {
        let postData = {}
        postData['func'] = 'listProducts';
        axios.post(airtableOps, postData)
            .then(function (response) {
                store.setState({ products: response.data })
                sessionStorage.setItem('products', JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.error(error);
            });
    }
}

export const handleAmount = (store, product, operator) => {
    if (operator == "+") {
        product.amount++;
        
    } else if (operator == "-") {
        if (product.amount > 0) {
            product.amount--;
        }
    }

    if (product.amount > 0) {
        product.selected = true;
    } else {
        product.selected = false;
    }

    store.setState(prevState => ({
        products: [...prevState.products, product]
    }))

    var tmp = []
    var products = store.state.products
    for (var i in products) {
        if (products[i].selected) {
            tmp.push(products[i]);
        }
    }

    store.setState({
        cart: tmp
    });
}